import React from 'react';
import cx from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';

import * as styles from './PageEmailForm.module.scss';
import { TextInput, Loader, SelectInput } from '../../components';
import { validateEmail } from '../../utils';
import { EmailApi } from '../../api';
import {
  IPageEmailForm,
  ITextInput,
  ISelectInput,
  IInput,
} from '../../interfaces';

interface IProps {
  pageEmailForm: IPageEmailForm;
}

interface IState {
  fields: {
    [id: string]: string;
  };
  open: {
    [id: string]: boolean;
  };
  captcha: string | null;
  error: boolean;
  loading: boolean;
  success: boolean;
  failure: boolean;
  replyEmailId: string;
}

const initialState = {
  fields: {},
  open: {},
  captcha: null,
  error: false,
  loading: false,
  success: false,
  failure: false,
  replyEmailId: '',
};

class PageEmailForm extends React.Component<IProps, IState> {
  readonly state: IState = initialState;

  emailApi = new EmailApi();

  componentDidMount() {
    this.clearInput();
  }

  render() {
    const { fields, error, success, failure, loading, open, captcha } =
      this.state;
    const { inputFields } = this.props.pageEmailForm;
    const isOdd = inputFields.length % 2 !== 0;

    return (
      <form
        className='centered-content vertical-margin'
        onSubmit={(e) => e.preventDefault()}
      >
        <div className={cx('columns is-multiline', styles.form)}>
          {inputFields.map((field, index) => {
            if (field.__typename === 'ContentfulTextInput') {
              const textInput = field as ITextInput;
              const isLast = index === inputFields.length - 1;
              const width =
                isOdd && isLast && textInput.type === 'textarea'
                  ? 'column is-12'
                  : 'column is-6-desktop is-12';

              return (
                <div key={textInput.id} className={width}>
                  <TextInput
                    title={textInput.title}
                    value={fields[textInput.id] || ''}
                    onChange={(e) =>
                      this.updateInput(e.target.value, textInput.id)
                    }
                    type={textInput.type}
                    name={textInput.name}
                    max={
                      textInput.max ||
                      (textInput.type === 'textarea' ? 500 : 100)
                    }
                    error={error && !this.validateField(textInput)}
                    errorText={textInput.errorText || 'This field is required'}
                  />
                </div>
              );
            }
            if (field.__typename === 'ContentfulSelectInput') {
              const selectInput = field as ISelectInput;
              return (
                <div key={selectInput.id} className='column is-6-desktop is-12'>
                  <SelectInput
                    title={selectInput.title}
                    value={fields[selectInput.id] || ''}
                    options={selectInput.options}
                    onChange={(val) => this.updateInput(val, selectInput.id)}
                    error={error && !this.validateField(selectInput)}
                    errorText={
                      selectInput.errorText || 'This field is required'
                    }
                    open={open[selectInput.id]}
                    toggleOpen={() => this.toggleOpen(selectInput.id)}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className={styles.submitContainer}>
          {!captcha ? (
            <ReCAPTCHA
              sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY as string}
              onChange={(c) => this.setState({ captcha: c })}
            />
          ) : (
              <button
                className={cx('basic-button', styles.submit)}
                onClick={this.sendEnquiry}
              >
                {loading ? <Loader color='white' /> : 'Send'}
              </button>
            )}
          {(success || failure) && (
            <strong>
              {success ? 'Enquiry has been sent!' : 'Unable to send enquiry'}
            </strong>
          )}
        </div>
      </form>
    );
  }

  updateInput = (value: string, id: string) => {
    const { fields } = this.state;
    fields[id] = value;
    this.setState({ fields });
  };

  toggleOpen = (id: string) => {
    const { open } = this.state;
    open[id] = !open[id];
    this.setState({ open });
  };

  validateField = (field: IInput) => {
    const value = this.state.fields[field.id];
    if (!field.required && value.length === 0) {
      return true;
    }
    if (field.__typename === 'ContentfulTextInput') {
      const textInput = field as ITextInput;
      if (textInput.type === 'email') {
        return validateEmail(value);
      }
    }
    return value.length > 0;
  };

  sendEnquiry = async () => {
    this.setState({ loading: true, success: false, failure: false });
    const { inputFields, email, emailTitle, title } = this.props.pageEmailForm;
    const { fields, replyEmailId } = this.state;

    let isValid = true;
    for (const field of inputFields) {
      if (!this.validateField(field)) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      this.setState({ error: true, loading: false });
    } else {
      const resp = await this.emailApi.sendEmail(
        emailTitle || title,
        email,
        fields[replyEmailId],
        this.getEmailBody()
      );
      if (resp) {
        this.setState({ ...initialState, success: true, loading: false });
        this.clearInput();
      } else {
        this.setState({ failure: true, loading: false });
      }
    }
  };

  getEmailBody = () => {
    const { fields } = this.state;
    const { inputFields } = this.props.pageEmailForm;
    let emailBody = '';

    inputFields.forEach((field) => {
      emailBody += `<p><strong>${field.title}: </strong>${
        fields[field.id]
        }</p>`;
    });

    return emailBody;
  };

  clearInput = () => {
    const { fields } = this.state;
    const { inputFields } = this.props.pageEmailForm;
    inputFields.forEach((field) => {
      fields[field.id] = '';
      if (field.__typename === 'ContentfulTextInput') {
        const textField = field as ITextInput;
        if (textField.isReplyEmail) {
          this.setState({ replyEmailId: textField.id });
        }
      }
    });
    this.setState({ fields });
  };
}

export default PageEmailForm;
